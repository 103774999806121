import { render, staticRenderFns } from "./need_add.vue?vue&type=template&id=4508a4d6&"
import script from "./need_add.vue?vue&type=script&setup=true&name=needAdd&lang=js&"
export * from "./need_add.vue?vue&type=script&setup=true&name=needAdd&lang=js&"
import style0 from "./need_add.vue?vue&type=style&index=0&id=4508a4d6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports