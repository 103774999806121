<template>
  <el-dialog  custom-class="minWidthNeedAdd" @close="onClose"
              :title="form.id ? '查看求购信息' : '新增求购信息'"
              :visible.sync="visible" :close-on-click-modal="false" draggable>
    <el-form ref="dataFormRef" :model="form" :rules="dataRules" formDialogRef label-width="120px" v-loading="loading">
      <el-row :gutter="24">
        <el-col :span="12" class="mb20">
          <el-form-item label="标题" prop="title">
            <el-input v-model="form.title" placeholder="请输入标题" />
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb20">
          <el-form-item label="所属行政区" prop="district">
            <el-select v-model="form.district" placeholder="请选择所属行政区">
              <el-option v-for="item in land_area" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb20">
          <el-form-item label="联系人姓名" prop="contact">
            <el-input v-model="form.contact" placeholder="请输入联系人姓名" />
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb20">
          <el-form-item label="土地性质" prop="landType">
            <el-select v-model="form.landType" placeholder="请选择土地性质">
              <el-option v-for="item in land_type" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb20">
          <el-form-item label="联系人电话" prop="contactMobile">
            <el-input v-model="form.contactMobile" placeholder="请输入联系人电话" />
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb20">
          <el-form-item label="土地用途" prop="demandPurpose" >

            <el-input v-model="form.demandPurpose" placeholder="请输入土地用途" >

            </el-input>
          </el-form-item>




          <el-form-item label="" prop="subLandPurpose" label-width="10px" style="display: inline-block;" v-if="form.demandPurpose=='other_land'">
            <el-input v-model="form.subLandPurpose" placeholder="其他用地" />
          </el-form-item>


        </el-col>
        <el-col :span="12" class="mb20">
          <el-form-item label="土地面积" >
            <el-row :gutter="0" style="flex: 1">
              <el-col :span="11">
                <el-form-item prop="landSizeMin">
                  <el-input v-model="form.landSizeMin" placeholder="最小土地面积">
                    <template v-slot:append>平方米</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col style="text-align: center; line-height: 32px" :span="2">-</el-col>
              <el-col :span="11">
                <el-form-item prop="landSizeMax">
                  <el-input v-model="form.landSizeMax" placeholder="最大土地面积">
                    <template v-slot:append>平方米</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>


        <el-col :span="12" class="mb20">
          <el-form-item label="剩余使用年限" >
            <el-row :gutter="0" style="flex: 1">
              <el-col :span="11">
                <el-form-item prop="yearLimitMin">
                  <el-input v-model="form.yearLimitMin" placeholder="最小剩余使用年限">
                    <template v-slot:append>年</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col style="text-align: center; line-height: 32px" :span="2">-</el-col>
              <el-col :span="11">
                <el-form-item prop="yearLimitMax">
                  <el-input v-model="form.yearLimitMax" placeholder="最大剩余使用年限">
                    <template v-slot:append>年</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb20">
          <el-form-item label="期望价格">
            <el-row :gutter="0" style="flex: 1">
              <el-col :span="11">
                <el-form-item prop="expectPriceLow">
                  <el-input v-model="form.expectPriceLow" placeholder="最小期望价格">
                    <template v-slot:append>万元</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col style="text-align: center; line-height: 32px" :span="2">-</el-col>
              <el-col :span="11">
                <el-form-item prop="expectPriceHigh">
                  <el-input v-model="form.expectPriceHigh" placeholder="最大期望价格">
                    <template v-slot:append>万元</template>
                  </el-input>
                </el-form-item>
                <div class="sub-title"><span style="color: #1e88fb">不填默认为面议</span></div>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>


        <el-col :span="24" class="mb20">
          <el-form-item label="补充说明" prop="extraData">
            <el-input type="textarea" v-model="form.extraData" placeholder="请输入其他补充说明" />
          </el-form-item>
        </el-col>
<!--        <el-col :span="12" class="mb20">-->
<!--          <el-form-item label="是否发布" prop="isPublished">-->
<!--            <el-select v-model="yesOrNoComputed" placeholder="请选择是否启用">-->
<!--              <el-option :value="item.value" :label="item.label" v-for="(item, index) in yes_no_type" :key="index"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </el-col>-->

      </el-row>
      <facerecognition-dialog ref="facerecognitionRef"     />
    </el-form>
    <template #footer v-if="!form.id">
			<span class="dialog-footer">
				<el-button @click="visible = false">取消</el-button>
				<el-button type="primary"  v-prevent-re-click @click="onverify" :disabled="loading">确认</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup  name="needAdd">
import { useDict } from '@/hooks/dict';
import {reactive, watch, ref, nextTick, computed, defineAsyncComponent} from 'vue'
import {Message} from 'element-ui'
import {getNeedObj, addNeedObj, putNeedObj, getidCardInfo} from '@/service/CommonService';
import { rule } from '@/utils/validator';
import { preventReClick } from '@/utils/preventReClick'
const emit = defineEmits(['refresh']);
import debounce from 'lodash/debounce';

const facerecognitionDialog = defineAsyncComponent(() => import('./FaceRecognition.vue'));
const facerecognitionRef = ref();

const { yes_no_type, land_type, land_area, demand_purpose } = useDict('yes_no_type', 'land_type', 'land_area', 'demand_purpose');

// 定义变量内容
const dataFormRef = ref();
const visible = ref(false);
const loading = ref(false);

// 提交表单数据
const form = reactive({
  id: '',
  title: '',
  district: '',
  landType: '',
  demandType: 1,
  userverify:'',
  demandPurpose: '',
  demandPurposeItem: '',
  landSizeMax: '',
  landSizeMin: '',
  expectPriceLow: '',
  expectPriceHigh: '',
  yearLimitMin: '',
  yearLimitMax: '',
  contact: '',
  contactMobile: '',
  isPublished: 1,
  extraData: '',
});

const forms = reactive({
  timer:null,
  userverify:false
})


const onverify= async () =>
{
  const valid = await dataFormRef.value.validate().catch(() => {});
  if (!valid) return false;


  if(  localStorage.getItem("login_type")!="ca")
  {
    lunxunData();
    facerecognitionRef.value.openDialog('','', (data) => {
      window.console.log('1')
      stop();
    });

  }else {
    onSubmit();
  }
}


const onClose= () =>{
  stop();
}


const lunxunData = () => {
  if(forms.timer ==null)
  {
    forms.timer = setInterval(() => {
      setTimeout(() => {
        checkQrCode()
      }, 0)
    }, 3000)
  }

}

const  checkQrCode  = async() =>  {

  const res = await getidCardInfo();

  // window.console.log(res)
  if (res.data.checked === 1) {
    // 803表示登录成功，以下做登录成功后的事件
    window.console.log('登录成功')
    stop()
    form.userverify='1';
    forms.userverify=true;
    onSubmit();
  } else if (res.data.code === 800) {
    // 803表示二维码过期
    window.console.log('二维码过期')
    // 开启css覆盖当前二维码

    // 停止轮询
    stop()
  }
}


const stop  = () => {
  window.console.log('2')
  window.console.log(forms.timer)
  window.clearInterval(forms.timer)
  clearInterval(forms.timer);
  forms.timer = null
}
//
const demandPurposeItemOptionsComputed = computed(() => {
  const options = useDict(form.demandPurpose);
  return options[form.demandPurpose].value;
});

const yesOrNoComputed = computed({
  get: () => {
    return form.isPublished + '';
  },
  set: (value) => {
    form.isPublished = parseInt(value);
  },
});

const clearSelect = () => {
  form.demandPurposeItem = '';
};

// watch(
//     () => form.demandPurpose,
//     () => {
//       form.demandPurposeItem = '';
//     }
// );

// 定义校验规则
const dataRules = ref({
  title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
  district: [{ required: true, message: '请选择所属行政区', trigger: 'change' }],
  landType: [{ required: true, message: '请选择土地性质', trigger: 'change' }],
  demandPurpose: [{ required: true, message: '请输入土地用途', trigger: 'change' }],
  demandPurposeItem: [{ required: true, message: '请选择土地二级用途', trigger: 'blur' }],
  landSizeMin: [{ required: true,message: '请输入最小面积',  trigger: 'blur' }],
  landSizeMax: [{ required: true,message: '请输入最大面积',  trigger: 'blur' }],
  expectPriceLow: [{ validator: rule.number, trigger: 'blur' }],
  expectPriceHigh: [{ validator: rule.number, trigger: 'blur' }],
  yearLimitMin: [{ required: true,message: '请输入最小使用年限',  trigger: 'blur' }],
  yearLimitMax: [{ required: true,message: '请输入最大使用年限', trigger: 'blur' }],
  contact: [{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
  userverify: [{ required: true, message: '请先进行人脸识别', trigger: 'blur' }],
  contactMobile: [
    { required: true, message: '请输入联系人电话', trigger: 'blur' }
  ],
});

// 打开弹窗
const openNewDialog = (id) => {
  visible.value = true;
  form.id = '';

  // 重置表单数据
  nextTick(() => {
    dataFormRef.value?.resetFields();
  });

  // 获取demandLand信息
  if (id) {
    form.id = id;
    getdemandLandData(id);
  }
  lunxunData();
};

// 提交
const onSubmit = debounce( async () => {



  const valid = await dataFormRef.value.validate().catch(() => {});
  if (!valid) return false;
  if(!forms.userverify)
  {
    return  Message.error("人脸识别未成功!");
  }
  try {
    loading.value = true;
    form.id ? await putNeedObj(form) : await addNeedObj(form);
    Message.success(form.id ? '修改求购信息成功' : '发布求购信息成功');
    visible.value = false;
    facerecognitionRef.value.closeDialog();
    emit('refresh');
  } catch (err) {
    Message.error(err.msg);
  } finally {
    loading.value = false;
  }
}, 2000);

// 初始化表单数据
const getdemandLandData = (id) => {
  // 获取数据
  loading.value = true;
  getNeedObj(id)
      .then((res) => {
        Object.assign(form, res.data);
      })
      .finally(() => {
        loading.value = false;
      });
};

// 暴露变量
defineExpose({
  openNewDialog,
  stop
});
</script>

<style>
.minWidthNeedAdd{
  min-width: 1060px !important;
}
</style>

